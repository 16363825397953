<template>
  <div style="font-size:16px;">
    <h1>{{ eventData.inetName }}</h1>
    Total Available Tickets: {{ options.totalAvailableSeats.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
    <b-col
      v-for="item in options.ticketTypes"
      :key="item.code"
      md="5"
      lg="4"
      xl="3"
    >
      <br><br>
      <div v-if="item.name">{{ item.name }}
        ${{ (item.price.amount / 100) }}
        <h3>Projected Revenue for Event: ${{ (options.totalAvailableSeats * item.price.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h3>
      </div>
    </b-col>
  </div>

</template>

<script>
/* eslint-disable */
import {
  BCol
} from 'bootstrap-vue'

export default {
    components: {
    BCol,
  },
  data() {
    return {
      options: {totalAvailableSeats: 0},
      test: {},
      viewcount: {},
      mapped: null,
      eventData: {},
    }
  },
  async beforeMount() {
      var pathArray = window.location.pathname.split('/');
    const data = await (await fetch(`https://spring-meadow-6420.brime.workers.dev/corsproxy/?apiurl=https://am.ticketmaster.com/twg2022/api/v2/events/${pathArray[1]}/pricecodes?flow=buy&_format=json&time=1625108214606`)).json()
    const data2 = await (await fetch(`https://spring-meadow-6420.brime.workers.dev/corsproxy/?apiurl=https://am.ticketmaster.com/twg2022/api/member/eventmanager/v2/events?eventId=${pathArray[1]}&_format=json&time=1625109780274`)).json()
    // eslint-disable-next-line no-underscore-dangle
    // eslint-disable-next-line prefer-destructuring
    this.options = data._embedded.priceCodes[0]
    this.eventData = data2[0]
  },
  mounted() {
      this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered
  })
  },
}
</script>

<style>

</style>
